.emoji-button:hover {
  display: inline-block;
  will-change: transform;
  animation: wiggle 400ms infinite ease-in-out;
}

.custom-emoji {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.ded {
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/ded-b.png");
  &.dark {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/ded-w.png");
  }
}

.drink {
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/drink-b.png");
  &.dark {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/drink-w.png");
  }
}

.heart {
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/heart-b.png");
  &.dark {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/heart-w.png");
  }
}

.heartface {
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/heartface-b.png");
  &.dark {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/heartface-w.png");
  }
}

.key {
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/key-b.png");
  &.dark {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/key-w.png");
  }
}

.shade {
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/shade-b.png");
  &.dark {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/shade-w.png");
  }
}

.skull {
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/skull-b.png");
  &.dark {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/skull-w.png");
  }
}

.smile {
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/smile-b.png");
  &.dark {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/smile-w.png");
  }
}

.squid {
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/squid3-b.png");
  &.dark {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/emojis/squid3-w.png");
  }
}

@keyframes wiggle {
  0% { transform: rotate(-10deg); }
  50% { transform: rotate(10deg); }
  100% { transform: rotate(-10deg); }
}