.wide {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: auto;
}

.clipboard-glitch, .tape-glitch, .sticker {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.clipboard-glitch {
  height: 15vh;
  top: 52.3%;
  left: 49.5%;
  transform: translate(-50%, -50%);
  animation: clipboard 15s infinite;

  img {
    height: 100%;
  }

  &:hover {
    animation: none;
  }
}

@keyframes clipboard {
  0% { opacity: 0; }
  40% { opacity: 0; }
  50% { opacity: 1; }
  80% { opacity: 0; }
  100% { opacity: 0; }
}

.tape-glitch {
  height: 10vh;
  top: 71%;
  left: 45.8%;
  transform: translate(-50%, -50%);
  animation: tape 10s infinite;

  img {
    height: 100%;
  }

  &:hover {
    animation: none;
  }
}

@keyframes tape {
  0% { opacity: 0; }
  10% { opacity: 0.5; }
  20% { opacity: 0; }
  40% { opacity: 0.5; }
  50% { opacity: 1; }
  60% { opacity: 0; }
  75% { opacity: 0.75; }
  100% { opacity: 0; }
}

.sticker {
  height: 23vh;
  top: 43%;
  left: 62%;
  transform: translate(-50%, -50%);
  // animation: tape 10s infinite;

  .sticker-glitch {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 4s ease-out;
  }

  img {
    height: 100%;
  }

  &:hover {
    .sticker-glitch {
      opacity: 1;
      transition: opacity 4s ease-in;
    }
  }
}

.recorder-button {
  position: absolute;
  bottom: 6%;
  height: 15vh;
  width: 9vh;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background:  transparent;
  /*background: rgba(0,0,0,0.5);*/
  /*opacity: 0;
  transition: opacity 200ms ease-out;*/

  /*&:hover {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }*/

  &.record {
    left: 36.5%;
  }

  &.rewind {
    left: 43%;
  }

  &.play {
    left: 49.5%;
  }

  &.ff {
    left: 56%;
  }

  &.pause {
    left: 62.5%;
  }

  &.ftp {
    height: 5vh;
    width: 9vh;
    bottom: 26%;
    left: 36.5%;
  }
}

.clipboard-page {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 60vh;

  img {
    height: 100%;
  }

  .turn-page {
    position: absolute;
    bottom: -1rem;
    font-size: 2rem;

    &.next {
      right: 0;
    }

    &.back {
      left: 0;
    }
  }
}
