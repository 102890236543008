.green-room-content {
  .wide-view {
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    /*object-fit: cover;*/
    /*margin: auto;*/
  }

  .water, .locker-static {
    position:absolute;
    top:50%;
    left:50%;
    height:100%;
    width:auto;
    transform: translate(-50%, -50%);
  }
  .water {
    height: 37.4vh;
    width:auto;
    transform: translate(-138.5%, 19%);
  }
  
  .locker-static {
    z-index: -1;
    width:30%; 
    height:auto;
    transform: translate(-50%, -40%);
  }
}

.locker-close-content {
  .locker-static {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-175%, -40%);
    z-index: -1;
    width:30%; 
    height:80vh;
  }
}

.locker-view {
  height: 200%;
  max-width: 200%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -30%);
  will-change: transform;
  transition: transform 1s ease-in-out;
}

.locker-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.blur {
  filter: blur(10px);
}

.enter {
  opacity: 1;
  will-change: opacity;
  transition: opacity 600ms ease-in-out;

  button {
    pointer-events: all;
  }
}

.locker-door {
  position: absolute;
  height: 33vh;
  top: 47vh;
  left: 50%;
  transform: translateX(-143%);
  cursor: pointer;
  overflow: hidden;
  z-index: 9;

  img {
    height: 100%;
  }

  .door-glitch {
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/locker-door-rollover.gif");
      background-size: contain;
    }
  }
}

.book-button {
  position:absolute;
  height: 47vh;
  width:auto;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(8%, -34%);
  will-change: transform;
  transition: transform 1s ease-in-out;

  .book-glitch {
    height: 100%;
  }

  &:hover {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/locker-book-glitch.gif");
      background-size: contain;
    }
  }

}

.poster-button {
  position:absolute;
  height: 60vh;
  width: 40vh;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(77%, 30%);
  will-change: transform;
  transition: transform 1s ease-in-out;

  .poster-fill {
    height: 100%;
    width: 100%;
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/poster-sprite.png");
    background-size: 200% 100%;
    background-position-x: 0%;

    &.glitch {
      animation: poster-glitch 5s steps(2) infinite;
    }
  }

  .email-glitch {
    position: absolute;
    bottom: 3vh;
    width: 80%;
    height: 20%;
    left: 50%;
    transform: translateX(-50%) rotate(4deg);
  }
}

.book-band-button {
  position:absolute;
  height: 78.5vh;
  width: auto;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(263%, -53%) rotate(-2.5deg);

  img {
    height: 100%;
  }

  &:hover {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/book-band-rollover-glitch.gif");
      background-size: contain;
    }
  }
}

@keyframes poster-glitch {
  0% {background-position-x: 0%;}
  9% { background-position-x: 0%}
  10% { background-position-x: 200%}
  20% { background-position-x: 0%}
  100% {background-position-x: 0%;}
}

.mme-saluer-poster {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11;
  width: auto;
  height: 80vh;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  transition: opacity 1s ease-in-out;

  &.show {
    opacity: 1;
    will-change: opacity;
    transition: opacity 1s ease-in-out;
  }

  .description {
    position: absolute;
    transform: translate(-9999px, -9999px);
  }

  img {
    height: 100%;
  }
}

.slide {
  .locker-view {
    transform: translate(-50%, -60%);
    will-change: transform;
    transition: transform 1s ease-in-out;
  }

  .book-button {
    transform: translate(8%, -162%);
    will-change: transform;
    transition: transform 1s ease-in-out;
  }

  .poster-button {
    transform: translate(77%, -65%);
    will-change: transform;
    transition: transform 1s ease-in-out;
  }
}

.poster-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  pointer-events: auto;
}

.journal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  pointer-events: auto;

  #journal {
    max-height: 100vh;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-3deg);
  }

  img {
    max-height: 90vh;
/*    will-change: transform;
    transform: scale(0);
    transition: transform 300ms ease-in-out;*/
    pointer-events: none;
  }

  /*&.book-open button {
    transform: translate(-60%, -45%) rotate(-3deg);
  }*/

  &.active {
    background: rgba(0,0,0,0.5);
  }

  &.active img {
/*    will-change: transform;
    transform: scale(1);
    transition: transform 300ms ease-in-out;*/
    pointer-events: none;
    margin: auto;
  }
}

.journal-back-page {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 82vh;
  width: 45vh;
  transform: translate(-138%, -54%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  /*pointer-events: auto;*/
  /*user-select: auto;*/
  /*border: 2px solid blue;*/
}

.journal-page {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 82vh;
  width: 56vh;
  transform: translate(-28%, -54%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  padding: 1rem;
  /*pointer-events: auto;*/
  /*user-select: auto;*/
  /*border: 2px solid red;*/
}

.page-no {
  position: absolute;
  top: 4vh;
  right: 4vh;
}
