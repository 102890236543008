.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; 
}

.lobby-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: repeating-linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .1) 2px, transparent 2px, transparent 3px);
  pointer-events: none;
  z-index: 25;
}

.container-inner{
  width: 100%;
  height:100vh;
  display:flex;
  
  .content {
    width: 100%;
    height:100vh;
    overflow:hidden;
    position:relative;
    
    .center {
      position:absolute;
      z-index: 5;    
      top:calc(50% + 60px);
      left: 50%;
      transform: translate(-50%, -50%);
      .enter {
        opacity:0;
        display:none;
        user-select: none;
        -webkit-user-drag: none;
        &.visible {
           display:block;
           opacity:1;
        }
      }
      .countdown {
        color:var(--star-blue);
        font-size:80px;
        font-family:ostrich_sans_inlineregular_n;
        text-shadow: 0px 0px 12px var(--star-blue), 0px 0px 5px var(--star-blue);
        user-select: none;
      }
    }
  }
}

.video-container {
  position:absolute;
  top:70px;
  bottom: 50px;
  z-index: 1;
  width: 100%;
  height: calc(100%-120px);
  display: flex;
  justify-content: center;
  align-items: center;
  .video-stream {
    position:relative;
    flex: 1;
    /*width: 110%;*/
    /*height: calc(100vh-70px);*/
    /*top: 50%;*/
    /*left: -5%;*/
    /*transform: translateY(-50%);*/
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      bottom: 0;
      position: absolute;
      z-index: 2;
      background: rgb(0,0,0);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 0.9) 100%);
      pointer-events: none;
    }
  }

  .btn-container {
    bottom: 15vh;
  }
}

.curtain {
  width:50%;
  height:100%;
  position:absolute;
  z-index: 5;
  background: url("https://cdn.chorusproductions.com/eschaton-v2/lobby/curtain-sm.png") no-repeat;
  background-size:cover;
  top:0;

  .star{
    position:absolute;
    top: calc(50% + 60px);
    transform: translateY(-50%);
    user-select: none;
    -webkit-user-drag: none;
  }
  
  transition: all 1.2s ease-in-out;
  &.left {
    left:0;
    .star{ right:0; }
    &.open {
       left: -50%;
    }
  }
  &.right { 
    right:0;
    &.open {
       right: -50%;
    }
  }
}

.figure {
  display:block;
  position:absolute;
  z-index: 5;
  width: 15vw;
  height:100vh;
  top: 110px;
  -webkit-user-drag: none;
  pointer-events: none;
  user-select: none;

  transition-delay: .8s;
  &.left {
    left:0;
    transition: left 1s ease-in-out;
    img {
      width:100%;
      height:auto;
      position:absolute;
      &.anim1 {
        animation: fl-glitch1 15s steps(20, end) infinite;
        opacity:1;
      }
      &.anim2 {
        animation: fl-glitch2 6s infinite;
        opacity:0;
      }
      &.anim3 {
        animation: fl-glitch3 7s  infinite;
        animation-delay: 3s;
        opacity:0;
      }
      &.anim4 {
        animation: fl-glitch2 8s infinite;
        opacity:0;
        animation-delay: 4s;
      }
    }
    
    &.open {
      left:-50%;
    }
  }
  &.right {
    right:0;
    transition: right 1s ease-in-out;
    &.open {
      right:-50%;
    }
     img {
      width:100%;
      height:auto;
      position:absolute;
      &.anim1 {
        //animation: fl-glitch1 5s infinite;
        opacity:1;
      }
      &.anim2 {
        animation: fl-glitch2 5s infinite;
        opacity:0;
      }
      &.anim3 {
        animation: fl-glitch4 7s infinite;
        opacity:0;
      }
    }
  }
}

@keyframes fl-glitch1 {
  1% { opacity: 1; } 
  79% { opacity: 1; }
  80% { opacity: .7; }
  81% { opacity: 1;}
  100% { opacity: 1;}
}

@keyframes fl-glitch2 {
  1% { opacity: 0; }
  79% { opacity: 0;}
  80% { opacity: .1; }
  81% {opacity: 0;}
  100% { opacity: 0;}
}

@keyframes fl-glitch3 {
  1%   { opacity: 0;}
  83%  { opacity: 0;}
  84%  { opacity: .3;}
  85%  { opacity: 0;}
  100% { opacity: 0;}
}

 @keyframes fl-glitch4 {
   1% {opacity: 0;}
   14% { opacity: 0;}
   15% {opacity: .4; }
   16% {opacity: 0;} 
   17% {opacity: .6;} 
   18% {opacity: 0;} 
   100% { opacity: 0;}
}

.top {
  position:absolute;
  z-index: 6;
  top:0;
  left:0;
  width: 100%;
  .procenium {
    width:116%;
    /*left:-8%;*/
    display:block;
    position: relative;
    -webkit-user-drag: none;
    pointer-events: none;
    user-select: none;
  }
  .live {
    position:absolute;
    top: -4px;
    left:50%;
    transform:translateX(-50%);
    width:12vw;
    max-width:300px;
    z-index: 7;
    -webkit-user-drag: none;
    /*&.on {
      display:none;
    }*/
  }
}

.sidepiece {
  position:absolute;
  z-index: 8;
  bottom: -35px;
  -webkit-user-drag: none;
  pointer-events: none;
  user-select: none;
  &.left {
    transform: scaleX(-1);
    left: -25px;
  }
  &.right {
    right: -25px;
  }
}

.lobby-music-buttons {
  position: absolute;
  top: 12px;
  left: 24px;
  z-index: 25;
}

.lobby-cc-button {
  position: absolute;
  bottom: 6px;
  right: 24px;
  z-index: 25;
}

.neon-icon {
  font-size: 2rem;
  color: var(--star-blue);
  padding: 0.5rem;

  .fal {
    text-shadow: 0px 0px 12px var(--star-blue), 0px 0px 5px var(--star-blue);
  }
}

.tutorial-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  background-color: rgba(0,0,0,0.75);
}

.tutorial {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 8;
  width: 40vw;
}

.tutorial-text {
  display: flex;
  flex-direction: column;

  &.hidden {
    display: none;
  }

  button {
    align-self: flex-end;
  }
}

.tutorial-steps {
  display: flex;
  justify-content: center;

  .step {
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    padding: 4px;
    border-radius: 50%;
    background: rgba(255,255,255,0.5);
    box-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px  #D8E8F9; 

    &.current {
      background: white;
    }
  }

  button {
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }

  button:hover {
    .step {
      background: rgba(255,255,255,0.75);
    }
  }
}

.close-tutorial {
  position: absolute;
  top: 0;
  right: 8px;
}

.tutorial-next {
  display: block;
  text-decoration:underline;
  white-space: nowrap;
  &:hover {
    text-shadow: 0px 0px 20px rgba(147,197,249, .3), 0px 0px 5px rgba(147,197,249, 1);
  }
}
