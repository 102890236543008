.logo {
  position: absolute;
  width: 100%;
  top:8%;
  will-change: opacity;
  animation:flicker;
  animation-duration: .5s;
  animation-iteration-count: infinite;
}

.logo > img {
  position: absolute;
  display: block;
  height: auto;
  z-index:1;
  width:80vw;
  left: 10vw;
  opacity: .9;
}

.neon-flicker {
  position: absolute;
  top: 0;
  display: block;
  cursor: pointer;
  width: 11.2vw;
  left:22vw;
  height: 20vw;
  z-index:4;
}

.neon-flicker img {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
}

.neon-flicker img.bright {
  visibility:hidden;
  opacity:0;
}

.neon-flicker:hover img.normal {
  opacity:0;
  will-change: opacity;
  animation:glitchout;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.neon-flicker:hover img.bright {
  visibility:visible;
  will-change: opacity;
  animation:flareup;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}

.show-info {
  position:absolute;
  bottom:12vh;
  width: 100vw;
}

.showtime {
  color: #F0F7FF;
  font-family:'dm80_regular';
  font-size:22px;
  text-align:center;
  letter-spacing:.15rem;
  text-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px #D8E8F9;
  /*@media only screen and (max-width : 600px) {font-size:18px;}*/
}

.entrance-buttons {
  display: flex;
  justify-content: center;
  text-align: center;
  user-select: none;
  margin-top:8vh;
}

.show-link {
  color:#F0F7FF;
  text-decoration:none;
  font-family:ostrich_sans_inlineregular;
  font-size:44px;
  text-transform:uppercase;
  letter-spacing:.4rem;
  text-shadow: 0px 0px 20px rgba(147,197,249, .9),0px 0px 10px rgba(147,197,249, .7), 0px 0px 5px #D8E8F9;
  position:relative;
  display:block;
  cursor:pointer;
  transition: text-shadow .25s;
  margin: 0 3vw;
}

.show-link:hover {
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px rgb(147,197,249), 0 0 20px rgb(147,197,249), 0 0 25px rgb(147,197,249), 0 0 30px rgba(147,197,249, .5);        
  animation:flicker-on;
  animation-duration: .2s;
  animation-iteration-count: infinite;
  /*@media only screen and (max-width : 600px) {font-size:36px;}*/
}

@keyframes glitchout {
  0% {opacity: 0;}
  1% { opacity: 1;}
  2% { opacity: 0;}
  3% { opacity: .1;}
  4% { opacity: .8;}
  5% { opacity: 0;}
  6% { opacity: 0;}
  7% { opacity: 0;}
  8% { opacity: .5;}
  9% { opacity: 0; }
  10% {opacity: .8;}
  11% {opacity: 0;}
  34% {opacity: .1;}
  35% {opacity: .7;}
  36% {opacity: .1;}
  37% {opacity: 0;}
  38% {opacity: 1}
  39% { opacity: .1;}
  40% { opacity: .3;}
  41% { opacity: 0;}
  100% { opacity: 0;}
}

@keyframes flareup {
  $steps: 40;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      opacity: (random(10) / 10);
    }
  }  
}

@keyframes flicker {
  $steps: 10;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      opacity: (random(5) / 10) + .8;
    }
  }
  0% {
    opacity: .93;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: .93;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: .93;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: .93;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flicker-on {
  0% {
    opacity: .9;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: .9;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: .9;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flashing {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*Controls open/closed with body class*/
/*@mixin button-on {
  &:hover {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px rgb(147,197,249), 0 0 20px rgb(147,197,249), 0 0 25px rgb(147,197,249), 0 0 30px rgba(147,197,249, .5);        
    animation:flicker-on;
    animation-duration: .2s;
    animation-iteration-count: infinite;
  }   
}*/
@mixin button-off {
  color: #000;
  text-shadow: 0px 0px 30px rgba(0,0,0,.7); 
  cursor: not-allowed;
  &:before, :after {
    content: attr(data-text);
    color: rgba(255,255,255,.25);
    position: absolute;
    display:block;
    width:100%;    
  }
  &:before { top: -1px; left: -1px }
  &:after  { top: -2px; left: -2px } 
  &:hover {
    text-shadow: 0px 0px 30px rgba(0,0,0,.7);        
    animation:none;
  }
}

.show-closed .logo.on {
  display: none;
}

.show-closed .enter {
  @include button-off
}

.show-open .logo.off, .show-open .neon-flicker {
  display: none;
}

.show-open .enter {
  will-change: opacity;
  animation:flashing;
  animation-duration: .8s;
  animation-iteration-count: infinite;
  text-shadow: 0 0 5px #fff, 0 0 10px rgb(147,197,249), 0 0 15px rgb(147,197,249); 
}

.show-open .enter:hover {
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px rgb(147,197,249), 0 0 20px rgb(147,197,249), 0 0 25px rgb(147,197,249), 0 0 35px rgb(147,197,249); 
}

.mobile-notice {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  padding: 1rem 2rem;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: black;

  color: #F0F7FF;
  font-family:'dm80_regular';
  font-size:22px;
  text-align:center;
  letter-spacing:.15rem;
  text-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px #D8E8F9;
}

@media (max-width: 640px) {
  .mobile-notice {
    display: flex;
  }
}
