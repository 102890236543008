.static-tiled {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  height: 100%;
  width: 100%;
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/locker-static.gif");
  background-repeat: repeat;
}

.sound-booth-wide {
  z-index: -1;
  width: auto;
  max-width: 200%;

  height: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.terminal-button, .on-air, .slider, .knob {
  position:absolute;
  top:50%;
  left:50%;
  height:100%;
  width:auto;
  transform: translate(-50%, -50%);
}

.terminal-button {
  height: 8vh;
  width: auto;
  transform: translate(39%, 360%);
  cursor:  pointer;

  img {
    height: 100%;
  }
}

.on-air {
  height: 10vh;
  width: auto;
  transform: translate(-120%, -362%);
  font-family:ostrich_sans_inlineregular;
  font-size: 3rem;
  color: white;

  canvas {
    height: 100%
  }
}

.terminal {
  font-family:'fa-sysfont-c';
  color: black;
  font-size: 2rem;
}

.screen-close {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vh;
  height: 80vh;

  & > img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    user-select: none;
  }

  .terminal-login {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    padding: 1.5rem;
    background: white;
    border: 1px solid black;
    box-shadow: 3px 3px 0px black;
    /*height: 25%;
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/form-bg.png");
    background-repeat: no-repeat;
    background-size: contain;*/
    /*display: flex;
    flex-direction: column;*/

    form > div {
      text-align: right;
    }
  }

  .retro-window-bg {
    /*position: absolute;*/
    width: 100%;
    /*z-index: -1;*/
  }

  form {
    /*position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;*/
  }

  label {
    text-transform: uppercase;
    font-size: 1rem;
    display: flex;
  }

  .invalid {
    color: red;
    text-transform: uppercase;
    font-size: 1rem;
    margin-left: 1rem;
    margin-top: 0.25rem;
  }

  .retro-btn {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/login_btn_sprite.png");
    background-repeat: no-repeat;
    background-size: 100% 200%;
    background-position-y: 0%;
    text-transform: uppercase;
    font-size: 1rem;
    padding: 0.25rem 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    pointer-events: auto;

    &:hover {
      cursor: pointer;
      background-position-y: 100%;
      color: white;
    }
  }

  .terminal-input {
    & > div {
      display: inline-block;
    }

    .prompt {
      vertical-align: top;
      flex: 0 0 auto;
    }

    input {
      vertical-align: top;
      display: inline-block;
      margin-left: 1rem;
      pointer-events: auto;
      width: 100%;
      background: transparent;
      border: 1px solid black;
      flex: 1;
    }
  }
}

.transparent {
  opacity: 0;
}

@keyframes glitch-out {
  0% {opacity: 1;}
  10% { opacity: .2;}
  20% { opacity: 0;}
  30% { opacity: .4;}
  40% { opacity: 0;}
  50% { opacity: .5;}
  60% { opacity: 0;}
  70% { opacity: .6;}
  80% { opacity: 0;}
  90% { opacity: .7; }
  100% { opacity: 0;}
}

.desktop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .window-glitch {
    width: 80%;
    // height: 80%;
    max-height: 80%;
    overflow-y: auto;
    animation: glitch-in 0.25s 0.75s steps(11, end) forwards;
  }

  .window {
    position: absolute;
    top: 8vh;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid black;
    background: white;
    box-shadow: 3px 3px 0px black;
    pointer-events: auto;

    .top-bar {
      display: flex;
      justify-content: space-between;
      background: #ccc;
      border-bottom: 1px solid black;
      font-size: 1rem;
      padding-left: 1rem;
      pointer-events: auto;

      button {
        padding: 0 0.5rem;
      }
    }

    .blinking-cursor {
      color: black;
      background: black;
      margin-left: 0.25rem;
    }

    .desktop-text {
      padding: 1rem;
      font-size: 1rem;
    }

    .retro-btn {
      position: absolute;
      right: 0;
    }

    li {
      margin-bottom: 0.5rem;
      user-select: all;
      pointer-events: auto;
    }

    .redacted {
      text-transform: uppercase;
      background: black;
      color: var(--white);
      user-select: none;
      pointer-events: none;
    }
    .redlisted {
      text-transform: uppercase;
      background-image:
      linear-gradient(45deg, #000 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #000 75%),
      linear-gradient(45deg, transparent 75%, #000 75%),
      linear-gradient(45deg, #000 25%, #fff 25%);    
      background-size:2px 2px;
      background-position:0 0, 0 0, -1px -1px, 1px 1px;
      color: var(--white);
      font-weight: 800;
      letter-spacing: 0.25rem;
      user-select: none;
      pointer-events: none;
    }
    .redacted-glitch {
      position: relative;
      &:after {
        content: "redacted";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-transform: uppercase;
        background: black;
        color: var(--white);
        user-select: none;
        pointer-events: none;
        animation: glitch-out 0.5s 1s steps(11, end) forwards;
      }
    }
  }
}

.icon-container {
  position: absolute;
  top: 5vh;
  right: 3vw;
  display: flex;
  flex-direction: column;
}

.desktop-icon {
  position: relative;
  font-size: 0.75rem;
  color: black;
  cursor: pointer;
  pointer-events: auto;
  margin-top: 0.5rem;
  height: 10vh;
  align-self: center;
  border: 1px sold red;

  img {
    height: 100%;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  .filename {
    word-break: break-all;
    background: white;
    margin-top: 0.25rem;
    padding: 0.1rem 0.25rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  .fal, .fas {
    font-size: 3rem;
    margin-top: 0.5rem;
  }

  .file-glitch {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 107%;
    width: 120%;
    max-width: 120%;
    opacity: 0;
  }

  .file {
    opacity: 1;
  }
}

.glitch-icon:hover {
  .file {
    opacity: 0;
  }
  .file-glitch {
    opacity: 1;
  }
}

.forgot-btn {
  text-decoration: underline;
  font-size: 0.8rem;
}

.hint {
  opacity: 0;
  font-size: 0.8rem;
  pointer-events: auto;
  user-select: auto;

  &.show {
    animation: glitch-in 0.5s steps(11, end) forwards;
  }
}

.music-player {
  display: flex;
}

.music-btn-container {
  display: flex;
  margin-right: 1rem;

  button {
    padding: 0.25rem;
    border: 1px solid black;

    &:hover {
      background: black;
      color: white;
    }
  }
}

.music-info {
  display: flex;
  justify-content: space-between;
}

.music-download {
  text-decoration: underline;
  pointer-events: auto;
}

.seek-control {
  cursor: pointer;
  pointer-events: auto;
}

.crewlist {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.slider {
  height: 5vh;
  width: auto;

  img {
    height: 100%;
    width: auto;
  }
}

.knob {
  height: 1.5vh;
  width: 1.5vh;
}

@keyframes slider-1 {
  0% { transform: translateY(0%); }
  20% { transform: translateY(500%); }
  35% { transform: translateY(230%); }
  60% { transform: translateY(25%); }
  85% { transform: translateY(300%); }
  100% { transform: translateY(0%); }
}

@keyframes slider-2 {
  0% { transform: translateY(0%); }
  50% { transform: translateY(-400%); }
  100% { transform: translateY(0%); }
}

@keyframes slider-3 {
  0% { transform: translateY(0%); }
  50% { transform: translateY(-200%); }
  100% { transform: translateY(0%); }
}

@keyframes slider-4 {
  0% { transform: translateY(0%); }
  10% { transform: translateY(-100%); }
  45% { transform: translateY(150%); }
  65% { transform: translateY(0%); }
  70% { transform: translateY(100%); }
  100% { transform: translateY(0%); }
}

@keyframes slider-5 {
  0% { transform: translateY(0%); }
  50% { transform: translateY(-40%); }
  100% { transform: translateY(0%); }
}

.slider-1 {
  transform: translate(-840%, -250%);
  img {
    animation-name: slider-1;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-delay: 0s;
  }
}

.slider-2 {
  transform: translate(-740%, 60%);
  img {
    animation-name: slider-2;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-delay: 0s;
  }
}

.slider-3 {
  transform: translate(-640%, 80%);
  img {
    animation-name: slider-3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 1s;
  }
}

.slider-4 {
  transform: translate(-540%, 200%);
  img {
    animation-name: slider-4;
    animation-duration: 9s;
    animation-iteration-count: infinite;
    animation-delay: 0s;
  }
}

.slider-5 {
  transform: translate(-440%, -280%);
  img {
    animation-name: slider-5;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0s;
  }
}

.slider-6 {
  height: 6vh;
  transform: translate(170%, -580%);
  img {
    animation-name: slider-5;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-delay: 0s;
  }
}

.slider-7 {
  height: 6vh;
  transform: translate(510%, -580%);
  img {
    animation-name: slider-5;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-delay: 1s;
  }
}

.slider-8 {
  transform: translate(540%, 100%);
  img {
    animation-name: slider-2;
    animation-duration: 9s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-delay: 1s;
  }
}

.slider-9 {
  transform: translate(640%, -300%);
  img {
    animation-name: slider-1;
    animation-duration: 9s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
  }
}

.slider-10 {
  height: 6vh;
  transform: translate(850%, -570%);
  img {
    animation-name: slider-5;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-delay: 2s;
  }
}

@keyframes spin-1 {
  0% { transform:  translate(-50%, -50%) rotate(0deg); }
  50% { transform:  translate(-50%, -50%) rotate(180deg); }
  100% { transform:  translate(-50%, -50%) rotate(360deg); }
}

@keyframes spin-2 {
  0% { transform:  translate(-50%, -50%) rotate(0deg); }
  25% { transform:  translate(-50%, -50%) rotate(75deg); }
  100% { transform:  translate(-50%, -50%) rotate(0deg); }
}

@keyframes spin-3 {
  0% { transform:  translate(-50%, -50%) rotate(0deg); }
  25% { transform:  translate(-50%, -50%) rotate(45deg); }
  37% { transform:  translate(-50%, -50%) rotate(23deg); }
  65% { transform:  translate(-50%, -50%) rotate(150deg); }
  75% { transform:  translate(-50%, -50%) rotate(100deg); }
  90% { transform:  translate(-50%, -50%) rotate(240deg); }
  100% { transform:  translate(-50%, -50%) rotate(360deg); }
}

.knob-1 {
  top: 36%;
  left: 47.3%;
  animation-name: spin-1;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.knob-2 {
  top: 36%;
  left: 48.8%;
  animation-name: spin-1;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-direction: reverse;
}

.knob-3 {
  top: 36%;
  left: 50.3%;
  animation-name: spin-2;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.knob-4 {
  top: 36%;
  left: 51.8%;
  animation-name: spin-3;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

.knob-5 {
  top: 36%;
  left: 53.4%;
  animation-name: spin-2;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: reverse;
}


.knob-6 {
  height: 2vh;
  width: auto;
  top: 93.6%;
  left: 40.8%;
  animation-name: spin-2;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: reverse;
}

.knob-7 {
  height: 2vh;
  width: auto;
  top: 93.6%;
  left: 42.3%;
  animation-name: spin-3;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

.knob-8 {
  height: 2vh;
  width: auto;
  top: 93.6%;
  left: 43.8%;
  animation-name: spin-1;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.knob-9 {
  height: 2vh;
  width: auto;
  top: 93.6%;
  left: 45.3%;
  animation-name: spin-2;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.knob-10 {
  height: 2vh;
  width: auto;
  top: 93.6%;
  left: 46.8%;
  animation-name: spin-1;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-direction: reverse;
}