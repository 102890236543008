.smoke-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.smoke-container > video {
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
  z-index: 1;
}

.smoke-container .video-mask {
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  width: 110%;
  height: 110vh;
  top: -10vh;
  left: -10%;
  background: radial-gradient(ellipse farthest-corner, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 50%,rgba(0,0,0,.7) 100%);
  z-index: 2;
  pointer-events: none;
}

.smoke-container .overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: repeating-linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .1) 2px, transparent 2px, transparent 3px);
  z-index: 20;
}

.smoke-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}