.login {
  position:absolute;
  width: 85vw;
  height:100vh;
  left:10vw;
  top:0;
  display:flex;
  flex-direction:column;
  justify-content: center; 
}

.btn-container {
  position:absolute;
  bottom: 35vh;
  left: 10%;
  opacity:0;
  will-change: opacity;
  z-index: 5;
  pointer-events: none;
}

.btn-container.on {
  will-change: opacity;
  animation: glitch-in .5s 1s steps(11, end) forwards;
  pointer-events: all;
}

.btn-forward {
  position:relative;
  text-decoration:none;
  font-family:ostrich_sans_inlineregular;
  font-size:44px;     
  text-transform:uppercase;
  letter-spacing:.4rem;
  text-shadow: 0px 0px 20px rgba(147,197,249, .9),0px 0px 10px rgba(147,197,249, .7), 0px 0px 5px #D8E8F9;
  display:inline-block;
  cursor:pointer;
  transition: text-shadow .25s;
  user-select: none;
  /*@media only screen and (max-width : 600px) {font-size:36px;}*/
}

.btn-forward:hover {
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px rgb(147,197,249), 0 0 20px rgb(147,197,249), 0 0 25px rgb(147,197,249), 0 0 30px rgba(147,197,249, .5);        
  animation:flicker-on;
  animation-duration: .2s;
  animation-iteration-count: infinite;
}

.btn-forward.continue {
  opacity:1;
  background: linear-gradient(130deg, rgb(216,0,0) 0%, rgb(254,0,108) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 25px rgba(254,0,108, .5), 0px 0px 20px rgba(254,0,108, .9), 0px 0px 10px rgba(253,48, 72, .7), 0px 0px 5px rgba(253,48, 72, 1);
}

.btn-forward.continue:hover {
  text-shadow: 0px 0px 30px rgba(253,48, 72, 1), 0px 0px 25px rgba(254,0,108, 1), 0px 0px 20px rgba(254,0,108, 19), 0px 0px 10px rgba(253,48, 72, 1), 0px 0px 5px rgba(253,48, 72, 1);
}

.btn-forward.continue:hover:after {
  background-position: 0px -81px;
  animation:flashing;
  animation-duration: .7s;
  animation-iteration-count: infinite;
}

.btn-forward.continue:after {
  content: '';
  background: url("/images/continue_arrows.png") no-repeat 0 0;
  opacity:1;
  width:75px;
  height:81px;
  position:absolute;
  top:50%;
  transform: translateY(-50%);
  right: -55px;
}

.btn-back {
  position:relative;
  text-decoration:none;
  font-family:ostrich_sans_inlineregular;
  font-size:44px;     
  text-transform:uppercase;
  letter-spacing:.4rem;
  text-shadow: 0px 0px 20px rgba(147,197,249, .9),0px 0px 10px rgba(147,197,249, .7), 0px 0px 5px #D8E8F9;
  display:inline-block;
  cursor:pointer;
  transition: text-shadow .25s;
  user-select: none;
  /*@media only screen and (max-width : 600px) {font-size:36px;}*/
}

.btn-back:hover {
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px rgb(147,197,249), 0 0 20px rgb(147,197,249), 0 0 25px rgb(147,197,249), 0 0 30px rgba(147,197,249, .5);        
  animation:flicker-on;
  animation-duration: .2s;
  animation-iteration-count: infinite;
}

.btn-back.continue {
  opacity:1;
  background: linear-gradient(130deg, rgb(216,0,0) 0%, rgb(254,0,108) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 25px rgba(254,0,108, .5), 0px 0px 20px rgba(254,0,108, .9), 0px 0px 10px rgba(253,48, 72, .7), 0px 0px 5px rgba(253,48, 72, 1);
}

.btn-back.continue:hover {
  text-shadow: 0px 0px 30px rgba(253,48, 72, 1), 0px 0px 25px rgba(254,0,108, 1), 0px 0px 20px rgba(254,0,108, 19), 0px 0px 10px rgba(253,48, 72, 1), 0px 0px 5px rgba(253,48, 72, 1);
}

.btn-back.continue:hover:before {
  background-position: 0px -81px;
  animation:flashing;
  animation-duration: .7s;
  animation-iteration-count: infinite;
}

.btn-back.continue:before {
  content: '';
  background: url("/images/continue_arrows.png") no-repeat 0 0;
  opacity:1;
  width:75px;
  height:81px;
  position:absolute;
  top:50%;
  transform: translateY(-50%) rotate(180deg);
  left: -65px;
}

.console {
  p, form, .typing, .link {
    margin-top: 10px;
    color: var(--white);
    font-family:'dm80_regular';
    font-size:22px;
    text-align:left;
    letter-spacing:.15rem;
    text-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px #D8E8F9;
    text-transform:uppercase;
    span {
      display: inline-block;
      height: 35px;
    }
  }
  .link {
    text-decoration:underline;
    white-space: nowrap;
    &:hover {
      background: var(--white);
      color: black;
      text-decoration: none; 
      box-shadow: 0px 0px 20px rgba(147,197,249, .3), 0px 0px 5px rgba(147,197,249, 1);
    }
  }

  .error {
    color: #F60037;
    text-shadow: 0px 0px 30px rgba(254,0,108, .8), 0px 0px 5px rgba(253,48, 72, 1);
    .link {
      color: #F60037;
      text-decoration:underline;
      text-shadow: 0px 0px 30px rgba(254,0,108, .8), 0px 0px 5px rgba(253,48, 72, 1);
      &:hover{
        background: #F60037;
        color: black;
        text-decoration: none;
        box-shadow: 0px 0px 20px rgba(254,0,108, .3), 0px 0px 5px rgba(253,48, 72, 1);
      }
    }
  }

  label {
    display: flex;
  }

  input {
    color: var(--white);
    font-family:'dm80_regular';
    font-size:22px;
    text-align:left;
    letter-spacing:.15rem;
    text-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px #D8E8F9; 
    /*text-transform:uppercase;*/
    background:transparent;
    outline:none;
    border:none;
    display:inline;
    margin-left: 22px;
    flex: 1;
  }
}

.typewriter-text {
  position:absolute;
  width: 85%;
  height: 50vh;
  left:10%;
  top:0;
  z-index:5;
  opacity:.8;
  display:flex;
  flex-direction:column;
  justify-content:flex-end;
  @media only screen and (max-width : 800px) {font-size:18px;}
  &.long-text {justify-content:flex-end;}
}

.liminal {
  .typing {
    color: var(--bright-green);
    text-shadow: 0px 0px 30px rgba(48,255,0, .8), 0px 0px 5px #30FF00;
  }
  .unknown {
    color: var(--star-blue);
    text-shadow: 0px 0px 30px rgba(51,167,233, .8), 0px 0px 5px #33a7e9;
  }
}

@keyframes glitch-in {
  0% {opacity: 1;}
  10% { opacity: .2;}
  20% { opacity: 0;}
  30% { opacity: .4;}
  40% { opacity: 0;}
  50% { opacity: .5;}
  60% { opacity: 0;}
  70% { opacity: .6;}
  80% { opacity: 0;}
  90% { opacity: .7; }
  100% { opacity: 1;}
}

@keyframes flicker {
   0% { opacity: .88;}
  10% { opacity: 1;}
  20% { opacity: .88;}
  30% { opacity: 1;}
  40% { opacity: .88;}
  50% { opacity: 1;}
  60% { opacity: 1;}
  70% { opacity: .88;}
  80% { opacity: 1;}
  90% { opacity: 1;}
  100%{ opacity: 1;}
}

@keyframes flicker-on {
  0% { opacity: .9;}
  10% { opacity: 1;}
  20% { opacity: .9;}
  30% { opacity: 1;}
  40% { opacity: .9;}
  50% { opacity: 1;}
  60% { opacity: 1;}
  70% { opacity: 1;}
  80% { opacity: 1;}
  90% { opacity: 1;}
  100% { opacity: 1;}
}

@keyframes flashing {
  0% {opacity: 0;}
  30% {opacity: 1;}
  70% {opacity: 1;}  
  100% {opacity: 0;}  
}
