.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; 
}

.chat-container {
  position: relative;
  height: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  background-color: black;
  font-family: "dm80_regular";
/*  --white: #F0F7FF;
  --cool-white: #DFEEFF;
  --green: #40c402;*/
  color: var(--white);

  border-width: 6px;
  border-left-color: rgb(14, 39, 117);
  border-right-color: rgb(14, 39, 117);
  border-top-color: rgb(67, 94, 122);
  border-bottom-color: rgb(32, 16, 64);

  .overlay {
    opacity: 0.5;
  }

  .note {
    padding: 0 1rem;
  }

  &.disabled {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/static.gif");
    background-repeat: repeat;
  }

  &.in {
    width: 300px;
    max-width: 300px;
  }
}

.chat-popped {
  transform: rotate(180deg);
}

.chat-container .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: repeating-linear-gradient(0deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .2) 1px, transparent 2px, transparent 1px);
  pointer-events: none;
  z-index: 10;
}

.chat-header {
  position: relative;
  /*padding: 1rem 1rem 0 1rem;*/
  filter: contrast(110%);
  /*border-bottom: 1px solid var(--white);*/
  /*display: flex;*/
  justify-content: center;
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/lobby/blue-velvet.jpg");
  background-repeat: repeat;
}

.chat-header img {
  width: 80%;
  margin: 1rem auto;
  user-select: none;
  -webkit-user-drag: none;
}

.chat-location {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.chat-location-bg {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(black, transparent 33%, transparent 67%, rgba(0,0,0,0.75)), linear-gradient(90deg, black, transparent 5%, transparent 95%, rgba(0,0,0,0.75));
}

.chat-location p {
  color: #D51126;
  font-size: 1rem;
  text-shadow: 0px 0px 5px #D51126;
  letter-spacing: 0.15rem;
  filter: contrast(110%) brightness(150%);
  padding: 0.5rem;
}

.disabled .chat-header {
  /*background: black;*/
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/common/static.gif");
}

.disabled .chat-header h1 {
  color: var(--green);
  text-shadow:  0px 0px 40px var(--green), 0px 0px 8px var(--green), 0px 0px 3px var(--green);
}

.chat-block {
  position: relative;
  padding: 1rem;
}

.chat-tabs {
  position: relative;
  width: 100%;
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/lobby/blue-velvet.jpg");
  background-repeat: repeat;
}

.tab-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.chat-tabs button {
  display: flex;
  justify-content: center;
  flex: 1;
  font-size: 1.5rem;
  font-weight: 100;
  text-align: center;
  color: white;
  padding: 0.5rem;
  text-decoration: none;
  text-shadow: 0px 0px 10px rgba(147,197,249, .8), 0px 0px 5px var(--cool-white);
  cursor: pointer;
  background-image: linear-gradient(rgba(0,0,0,0), black);
  border-right: 1px solid black;

  &:last-child {
    border-right: none;
  }

  &:hover {
    text-shadow: 0px 0px 35px var(--cool-white), 0px 0px 25px var(--cool-white), 0px 0px 20px var(--cool-white), 0px 0px 15px var(--cool-white), 0px 0px 10px var(--cool-white), 0px 0px 5px var(--cool-white);
  }

  &.selected {
    text-shadow: 0px 0px 35px var(--cool-white), 0px 0px 25px var(--cool-white), 0px 0px 20px var(--cool-white), 0px 0px 15px var(--cool-white), 0px 0px 10px var(--cool-white), 0px 0px 5px var(--cool-white);
    background: transparent;
    /*background-image: linear-gradient(0deg, rgba(0,0,0,0.5), transparent 10%, transparent 90%, rgba(255,255,255,0.5)), linear-gradient(90deg, rgba(255,255,255,0.5), transparent 10%, transparent 90%, rgba(0,0,0,0.5));*/
  }

  &.on {
    /*animation:throbbing;
    animation-duration: 1s;
    animation-iteration-count: infinite;*/
    color: #F60037;
    text-shadow: 0px 0px 30px rgba(254,0,108, .8), 0px 0px 5px rgba(253,48, 72, 1);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.chat-tabs .bump {
  display: block;
  width: 100%;
  height: 6px;
  background-image: linear-gradient(rgba(25,128,255 ,0.5), rgba(0,0,0,0.5));
}

@keyframes throbbing {
  0% {
    opacity: .6;
    text-shadow: 0px 0px 5px var(--cool-white);
  }
  50% {
    opacity:1;
    text-shadow:  0px 0px 40px var(--cool-white), 0px 0px 35px var(--cool-white), 0px 0px 25px var(--cool-white), 0px 0px 20px var(--cool-white), 0px 0px 17px var(--cool-white), 0px 0px 15px var(--cool-white), 0px 0px 12px var(--cool-white), 0px 0px 10px var(--cool-white), 0px 0px 5px var(--cool-white);  
  } 
  100% {
    opacity: .6;
    text-shadow:  0px 0px 5px var(--cool-white);  
  }
}

/*@keyframes throbbing-text{
  0% {
    opacity: .8;
    text-shadow: 0px 0px 0 var(--cool-white);
  }
  50% {
    opacity:1;
    text-shadow:   0px 0px 30px var(--cool-white), 0px 0px 25px var(--cool-white), 0px 0px 20px var(--cool-white), 0px 0px 10px var(--cool-white), 0px 0px 5px var(--cool-white);  
  } 
  100% {
    opacity: .8;
    text-shadow:  0px 0px 0 var(--cool-white);  
  }
}*/

.chat-announcements {
  padding: 0.25rem;
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
}

.chat-message-container {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.5rem;
}

.message-line {
  padding: 0.25rem;
  font-size: 1rem;
  color: var(--white);
}

.message-header {
  display: flex;
  justify-content: space-between;
  opacity: 0.5;

  span:first-child {
    margin-right: 1rem;
  }
  span:last-child {
    user-select: none;
  }
}

.message {
  word-break: break-word;

  .at {
    text-decoration: underline;
  }
}

.notice {
  background: #222;
  padding:8px;
  border:1px solid #555;
  border-width: 1px 0;
}

.dm-header {
  position: relative;
  background-color: var(--white);
  color: black;
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 0.75rem;
  }
}

.dm-staff {
  font-style: italic;
}

.attendee-filter {
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 1rem;

  label, input {
    position: relative;
    width: 100%;
    height: 3rem;
  }

  input {
    background: none;
    color: var(--white);
    border: none;
    border-bottom: 1px solid var(--white);
    outline: none;
  }

  .filter-icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 98%;
    font-size: 1.25rem;
    padding: 0 0.75rem;
  }
}

.attendee-list {
  padding: 0 1rem;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;

  li:last-child {
    margin-bottom: 1rem;
  }
}

.attendee {
  color: var(--white);
  &:hover {
    text-decoration: underline;
  }

  &.has-message {
    text-decoration: underline;
    text-shadow: 1px 1px 1px var(--white);
  }
}

.chat-footer {
  background-color: var(--white);
  padding: 0.5rem;

}

.chat-footer .user-name {
  color: black;
  font-weight: 700;
}

/*.chat-footer select {
  @apply w-full mb-1 p-1 bg-white bg-opacity-15 text-white outline-none border-b-2 border-transparent focus:border-opacity-50 focus:border-white;
}*/

.chat-footer form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chat-footer textarea {
  min-width: 0;
  width: 100%;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
  background: transparent;
  font-size: 1rem;
  outline: none;
  color: rgba(0,0,0,.5);
}

.emoji-list {
  display: flex;
  justify-content: space-between;;
  width: 100%;
}

.emoji-list button {
  @apply flex justify-center items-center w-8 h-8 pl-1 pt-1 hover:bg-black hover:bg-opacity-25 focus:outline-none border-b-2 border-transparent focus:border-opacity-50 focus:border-white;
}

.usher {
  color: rgb(168, 32, 255);
}

.staff {
  color: var(--bright-green);
}

.disconnected {
  opacity: 0;
  pointer-events: none;
}

.unknown {
  color: var(--star-blue);

  .message {
    will-change: opacity;
    animation:flareup;
    animation-duration: 15s;
    animation-iteration-count: infinite;
  }
}

.staff .message-header:first-child {
  font-weight: bold;
}

.faq {
  padding: 1rem;

  button {
    margin-bottom: 1rem;
    text-align: left;
  }

  p {
    margin-bottom: 1rem;
  }
}