.forest-root {
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,64,1) 0%, #000088 20%, #0000ff 22%, #0000aa 25%, #8800aa 100%);
  background-size: cover;
  height:100vh;
  width: 100vw;
  overflow: hidden;

  ul {
    list-style-type:none;
    position:relative;
    width:33.3333%;
    height:100%;
    animation: wiggle 15s infinite/*, breathing 5s infinite*/;
    // transition: transform 1s;
  }
  ul:hover {
    cursor: pointer;
    // transform: scale(2);
    // transition: transform 1s;
    // animation: none;
  }
  ul:hover li {
    // opacity: .15;
    // filter: blur(10px)/* brightness(1.0)*/;
    // transition: filter 500ms;
    transform: scale(1.2);
  }
  ul:hover li:first-child {
    // opacity: 1; 
    // transition: opacity 1s;
    // filter: brightness(1.0);
  }
  li {
    position:absolute;
    width:100%;
    height:100%;
    color:white;
    opacity: .1;
    top:50px;
    left:0;
    transition: opacity 1s, filter 1s;
    filter: blur(10px);
    transition: filter 500ms ease-out;
    background-position: bottom;
    pointer-events: none;
  }
  li:first-child {
    // opacity: .3;
    opacity: 1;
    transition: /*opacity 1s,*/ filter 1s;
    filter: none;
    pointer-events: all;
  }

  ul.off li {
    filter: blur(5px) brightness(0.0);
    transition: filter 500ms ease-out;
  }

  ul.off li:first-child {
    opacity: 1;
    filter: brightness(0.0);
    transition: filter 500ms ease-out;
  }

  .left li {
    background: transparent url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/forest/1.png") no-repeat center center;
    background-size:contain;
  }
  .left li:first-child {
    position: relative;
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/forest/1.png");

    &::after {
      content: "";
      background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/forest/1-reflect.png");
      background-size:contain;
      background-position: top;
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateY(100%);
      filter: opacity(70%);
    }
  }
  .center li {
    background: transparent url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/forest/2.png") no-repeat center center;
    background-size:contain;
  }
  .center li:first-child {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/forest/2.png");

    &::after {
      content: "";
      background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/forest/2-reflect.png");
      background-size:contain;
      background-position: top;
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateY(100%);
      filter: opacity(70%);
    }
  }
  .right li {
    background: transparent url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/forest/3.png") no-repeat center center;
    background-size:contain;
  }
  .right li:first-child {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/forest/3.png");

    &::after {
      content: "";
      background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/forest/3-reflect.png");
      background-size:contain;
      background-position: top;
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateY(100%);
      filter: opacity(70%);
    }
  }
}
.container {
  display: flex;
  justify-content: top;
  align-items: center;
  margin: 25vh auto 0 ;
  height:60vh;
  max-height: 500px;
  width:136vh;
  max-width: 1100px;
}

@keyframes wiggle {
  0% { transform: translateX(-3%) scale(1); }
  50% { transform: translateX(3%) scale(1.05); }
  100% { transform: translateX(-3%) scale(1); }
}

@keyframes breathing {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}