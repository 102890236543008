.loading-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  overflow: hidden;
  padding: 0.75rem;
  color: rgba(255,255,255,0.9);
  font-size: 6rem;
  text-shadow: 0px 0px 20px rgba(147,197,249, .9),0px 0px 10px rgba(147,197,249, .7), 0px 0px 5px #D8E8F9;
}

.chat-loading {
  min-width: 300px;
  color: white;
  display: flex;
  justify-content: center;
}