.bg, .flicker {
  min-height: 100vh;
  height: auto;
  max-height: 110vh;
  min-width: 100vw;
  width: auto;
  max-width: 110vw;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

}

.flicker {
  position: absolute;
  opacity: 0;
  will-change: opacity;
  animation: glitch-in 2s 1s steps(11, end) infinite;
}

.glitch-bulb {
  position:absolute;
  top:50%;
  left:50%;
  height: 8vh;
  width: 8vw;
  transform: translate(-420%, -390%);
  z-index: 5;
  cursor: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/managements_office/hand.png"), auto;
}

.glitch-bulb-2 {
  position:absolute;
  top:50%;
  left:50%;
  height: 40vh;
  width: 20vw;
  transform: translate(-50%, -70%);
  z-index: 5;
  cursor: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/managements_office/hand.png"), auto;
}

.unscrewed {
  position:absolute;
  top:50%;
  left:50%;
  height: 100vh;
  width: 100vw;
  transform: translate(-50%, -50%);
  z-index: 5;
  cursor: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/managements_office/hand.png"), auto;
}

.mary-bulb {
  position:absolute;
  top:50%;
  left:50%;
  height: 30vh;
  width: 10vw;
  transform: translate(-130%, -50%);
  z-index: 5;
}

// .enter {
//   opacity: 1;
//   will-change: opacity;
//   transition: opacity 600ms ease-in-out;
// }

@keyframes glitch-in {
  0% {opacity: .8;}
  10% { opacity: .2;}
  20% { opacity: 0;}
  30% { opacity: .4;}
  40% { opacity: 0;}
  50% { opacity: .5;}
  60% { opacity: 0;}
  70% { opacity: .6;}
  80% { opacity: 0;}
  90% { opacity: .7; }
  100% { opacity: .8;}
}