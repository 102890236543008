.fame-frame {
  pointer-events: none;
  user-select: none;
}

.fame-chat {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .adoration-messages {
    position: relative;
    flex: 3 1 auto;
    overflow: hidden;
    padding-bottom: 2rem;
  }

  form {
    margin-top: 1rem;
    flex: 0;
  }

  label {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
  }

  ul {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  li {
    padding-left: 0.5rem;
    padding-bottom: 1rem;

    p:first-child {
      color: #59D41F;
      font-family:'dm80_regular';
      font-size:1rem;
      text-align:left;
      letter-spacing:.15rem;
      text-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px #59D41F;
      text-transform:uppercase;
    }

    p:last-child {
      color: #2F8707;
      font-family:'dm80_regular';
      font-size:0.75rem;
      text-align:left;
      text-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px #2F8707;
      text-transform:uppercase;
    }
  }

  textarea {
    background: transparent;
    outline: none;
    padding-left: 0.5rem;
    resize: none;

    color: #59D41F;
    font-family:'dm80_regular';
    font-size:1rem;
    text-align:left;
    text-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px #59D41F;

    &::placeholder {
      text-transform: uppercase;
      font-size: 1rem;
    }
  }
}

.fame-glitch {
  font-size: 1.5rem;
  will-change: opacity;
  animation: glitch-in .5s 1s steps(11, end) forwards;
}

.fame-adoration {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  max-width: 300px;
  padding-left: 1rem;
}

.screen-container {
  position: relative;
  height: 100vh;
  width: 100%;
}

.fame-video {
  position: absolute;
  top: 50%;
  right: 2.5%;
  transform: translateY(-50%);
  height: auto;
  width: 70%;
}

.big-screen {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 75%;
}

.send-love-btn {
  background-color: transparent;
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/fame/fame-send-button.png");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 7vh;
  cursor: pointer;

  &:hover {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/fame/fame-send-button_rollover.png");
  }
}