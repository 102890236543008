.tunnel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  opacity: 0;
  background: black;
}

.tunnel video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  max-height: 120vh;
  z-index: 10;
}

.on {
  opacity: 1;
  transition: opacity 2s;
}

.hidden {
  visibility: hidden;
}

.over video {
  transform: translateY(-100%);
  transition: transform 2s linear;
  border-bottom: 5px solid lime;
  box-shadow: 0 -10px 10px lime;
}

.skeleton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 60vw;
  z-index: 5;
}

.bottle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, -200%);
  width: 10vw;
  z-index: 5;
}

.message-in-bottle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 50vw;

  z-index: 6;

  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/puzzles/managements_office/scroll.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  .handwriting-sheila {
    font-size: 3.25vh;
    margin: 8vh 8vw;
    text-align: left;
  }
}

.skeleton-scene {
  // opacity: 0;
  width: 100vw;
  height: 100vh;
  // transform: translateY(100%);
  // transition: transform 2s linear;
}

.over .skeleton-scene {
  // transform: translateY(-100%);
  // transition: transform 2s linear;
}