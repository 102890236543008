.neon-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 110%;
  user-select: none;
  pointer-events: none;
  z-index: 12;
}

.prev-blocker, .next-blocker {
  position: absolute;
  top: 0;
  height: 100%;
  width: calc((100% - 100vh) / 2);
}

.prev-blocker {
  left: 0;
}

.next-blocker {
  right: 0;
}

/*.slider-next, .slider-prev {*/
.slick-next, .slick-prev {
  position: absolute;
  top: calc(50% + 5vh);
  z-index: 12;
  height: 25vh;
  width: 20vh;
  background-image: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/arrow-blue.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &::before {
    content: "";
  }

  &:hover {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/arrow-blue.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: brightness(2);
  }

  &:focus {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/arrow-blue.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    outline: none;
    box-shadow: inset 0px 0px 8px var(--white);
  }
}

.afterparty {
  .slick-next, .slick-prev {
    background-image: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/arrow-red.png");

    &:hover {
      background-image: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/arrow-red.png");
    }

    &:focus {
      background-image: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/arrow-red.png");
      box-shadow: inset 0px 0px 8px #F60037;
      img { filter: brightness(30%); }
    }
  }
}

.slider-next, .slick-next {
  right: calc((100% - 100vh) / 2 - 50px);
  transform: translateY(-50%);
}

.slider-prev, .slick-prev {
  left: calc((100% - 100vh) / 2 - 50px);
  transform: translateY(-50%) rotate(180deg);
}

.slider-container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 8;
  text-align: center;

  &.afterparty-slider {
    padding: 15vw;
  }
}

.spacer {
  height: 10vh;
}

.slider-vignette {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  pointer-events: none;
  background: radial-gradient(circle at center, transparent 40%, rgba(0,0,0,0.65) 75%);
}

.door-code {
  opacity: 0;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform-origin: center;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color:#F60037;
  font-size: 1.5rem;
  font-family:'dm80_regular';
  text-shadow: 0px 0px 12px #F60037, 0px 0px 5px #F60037;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  will-change: opacity;
  transition: opacity 200ms ease-in;
}

.door {
  position:relative;
  border:1px solid transparent;
  margin:0 auto;
  /*max-height: 60vh;*/
  max-width: 15vw;

  .door-content {
    width: 100%;
    height: 100%;
  }

  a:focus {/*
    outline: none;
    box-shadow: inset 0px 0px 8px var(--star-blue);*/
    img { filter: brightness(30%); }
    background: red;

    .door-code {
      opacity: 1;
      will-change: opacity;
      transition: opacity 200ms ease-in;
    }
  }
}

.finale-door {
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border:1px solid transparent;
  margin:0 auto;
  text-align: center;
  width: 80vh;

  .door-code {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .inner {
    clip-path: circle(36vh at center);
  }

  .door-content {
    width: 100%;
    height: 100%;
  }

  a:focus {/*
    outline: none;
    box-shadow: inset 0px 0px 8px var(--star-blue);*/
    img { filter: brightness(30%); }
    background: red;

    .door-code {
      opacity: 1;
      will-change: opacity;
      transition: opacity 200ms ease-in;
    }
  }
}

.portal-video {
  height: 80vh;
  object-fit: cover;
}

/*.portal-video {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
}*/

.afterparty.door:focus {
  outline: none;
  box-shadow: inset 0px 0px 8px #F60037;
}

.door .glow {
  position:absolute;
  width:100%;
  height:100%;
  z-index:10;
  background: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/door-red-glow.png") center top no-repeat;
  background-size: cover;
  mix-blend-mode: overlay;
  opacity:0;
  transition:opacity .3s;
}

.afterparty .glow {
  background: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/door-blue-glow.png") center top no-repeat;
  background-size: cover;
}

.finale-glow {
  position:absolute;
  width:100%;
  height:100%;
  z-index:10;
  background: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/finale-door-glow.png") center top no-repeat;
  background-size: cover;
  mix-blend-mode: overlay;
  opacity:0;
  transition:opacity .3s;
}

.frame {
  position:absolute;
  width:100%;
  height:100%;
  z-index:9;
  background: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/door-red-off.png") center top no-repeat;
  background-size: cover;
}

.afterparty .frame {
  background: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/door-blue-off.png") center top no-repeat;
  background-size: cover;
}

.finale-frame {
  position:absolute;
  width:100%;
  height:100%;
  z-index:9;
  background: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/finale-door.png") center top no-repeat;
  background-size: cover;
}

.inner img {
  width:69%;
  margin:16% auto 18%;
  filter: brightness(30%);
  transition:filter .3s;
}

.open {
  img { filter: brightness(100%);}
  
  .frame {
    background: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/door-red.png") center top no-repeat;
    background-size: cover;
  }

  &:hover {
    .glow{opacity:.5;}
    img {filter: brightness(30%);}
    .door-code {
      opacity: 1;
      will-change: opacity;
      transition: opacity 200ms ease-in;
    }
  }
}

.finale-door:hover {
  .glow{opacity:.5;}
  .portal-video {filter: brightness(30%);}
}

.afterparty.open {
  .frame{
    background: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/door-blue.png") center top no-repeat;
    background-size: cover;
  }
  .door-code {
    color: var(--star-blue);
    text-shadow: 0px 0px 12px var(--star-blue), 0px 0px 5px var(--star-blue);
  }
}

.new { 
  .glow, .finale-glow {
    will-change: opacity;
    animation:door-pulse;
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

.glitch { 
  .glow {
    /*animation: door-glitch 0.8s infinite;*/
    will-change: opacity;
    animation:flareup;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
  .inner {
    position: relative;
    /*animation: door-glitch2 2s infinite;*/

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width:69%;
      margin-left: 18%;
      height: 100%;
      background-image: url("https://cdn.chorusproductions.com/eschaton-v2/navigation/doors/glitch-door.gif");
      mix-blend-mode: luminosity;
      opacity: 0.5;
    }
  }
}

.closed {
  filter: grayscale(80%);
  &:hover {
    img {filter: brightness(10%);}
    .door-code {
      color: #aa5588;
      opacity: 1;
      will-change: opacity;
      transition: opacity 200ms ease-in;
    }
  }
}

@keyframes door-pulse {
  0% {opacity: 0;}
  50% {opacity: .8;}
  100% {opacity: 0;}
}

@keyframes door-glitch {
  1% { opacity: 0; }
  5% { opacity: 1; }
  6% { opacity: .7; }
  7% { opacity: 1;}
  79% { opacity: 0;}
  80% { opacity: .1; }
  81% { opacity: 0; }
  84%  { opacity: .3;}
  85%  { opacity: 0;}
  100% { opacity: 0;}
}
@keyframes door-glitch2 {
  1% { filter: brightness(.2); }
  5% { filter: brightness(1); }
  6% { filter: brightness(.7); }
  7% { filter: brightness(1);}
  79% { filter: brightness(.2);}
  80% { filter: brightness(.1); }
  81% { filter: brightness(.5); }
  84%  { filter: brightness(.3);}
  85%  { filter: brightness(.7);}
  100% { filter: brightness(1);}
}

.backup-link-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: var(--green);
  text-shadow:  0px 0px 40px var(--green), 0px 0px 8px var(--green), 0px 0px 3px var(--green);

  .backup-link {
    
    text-decoration:underline;
    &:hover {
      background: var(--green);
      color: black;
      text-decoration: none; 
      box-shadow: 0px 0px 20px rgba(147,197,249, .3), 0px 0px 5px rgba(147,197,249, 1);
    }
  }
}

.puzzle-fallback {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  text-shadow:  0px 0px 40px var(--white), 0px 0px 8px var(--white), 0px 0px 3px var(--white);
  text-align: center;
}

.room-key-wrapper {
  height: 25vh;
}

.room-key-container {
  position: relative;
  color: var(--white);
  font-family: dm80_regular;
  text-transform: uppercase;

  .hidden {
    transform: translate(-9999px, -9999px);
  }

  .blinking-cursor {
    position: absolute;
    left: 50%;
  }
}

.room-key-container label {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  text-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px #D8E8F9; 
}

.room-key-container input {
  display: block;
  width: 12rem; /* note: must match this input's maxlength */
  background: transparent;
  margin-top: 0.5rem;
  color: var(--white);
  font-family:'dm80_regular';
  font-size: 1.5rem;
  text-align:center;
  letter-spacing:.15rem;
  text-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px #D8E8F9; 
  text-transform:uppercase;
  background:transparent;
  outline:none;
  border:none;
}

.room-key-input {
  max-width: 40vw;
  display: inline-flex;
  font-size:  2.25rem;
  text-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px #D8E8F9; 
}

.room-key-invalid {
  font-size: 1rem;
  color: #F60037;
  text-shadow: 0px 0px 30px rgba(254,0,108, .8), 0px 0px 5px rgba(253,48, 72, 1);
}

.room-key-valid {
  color: var(--green);
  text-shadow:  0px 0px 40px var(--green), 0px 0px 8px var(--green), 0px 0px 3px var(--green);
}

span.blinking-cursor {
  font-weight: 100;
  color: #F0F7FF;
  font-size: 1em;
  padding-left: 2px;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  from, to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.photographer-icon {
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  z-index: 25;

  .neon-icon {
    font-size: 4rem;
  }
}

.no-rooms-notice {
  color: var(--white);
  font-family:'dm80_regular';
  font-size: 1.25rem;
  text-align:center;
  letter-spacing:.15rem;
  text-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px #D8E8F9; 
  text-transform: uppercase;
  max-width: 50vw;
  margin: 3rem auto;
}

