.puzzle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.puzzle-content {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100vh;
  width: 100%;
  transform: translateX(-50%);
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  transition: opacity 600ms ease-in-out;

  button {
    pointer-events: none;
  }
}

.back-buttons {
  position: absolute;
  top: 0;
  left: 65px;
  z-index: 100;
  pointer-events: all !important;
}

.puzzle-vignette {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  pointer-events: none;
  background-image: radial-gradient(circle, transparent 60%, black 85%);
}

.backstage-neon-ring {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 12;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 150vh;
    max-width: 150vh;
    user-select: none;
  }

  .glow{
    opacity:0;
    animation: 3s ease-in-out infinite alternate ring-glow;
    mix-blend-mode: screen;
  }

  &.zoom {
    img {
      height: 200vh;
      max-width: 200vh;
    }
  }
}

@keyframes ring-glow {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@font-face {
  font-family: 'newellshandregular';
  src: url('/fonts/handwriting/newell_h-webfont.woff2') format('woff2'),
       url('/fonts/handwriting/newell_h-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'gunny_rewrittenregular';
  src: url('/fonts/handwriting/gnyrwn971-webfont.woff2') format('woff2'),
       url('/fonts/handwriting/gnyrwn971-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.handwriting-mary {
  font-family: 'gunny_rewrittenregular';
  font-size: 3vh;
  text-align: left;
  font-color: rgba(0,0,0,0.92);
  pointer-events: none;
  margin-bottom: 1rem;
}

.handwriting-sheila {
  font-family: 'newellshandregular';
  font-size: 4vh;
  text-align: right;
  font-color: rgba(128,64,32,0.92);
  pointer-events: none;
  margin-bottom: 1rem;
}