.modal {
  display:none;
  &.visible {
    display:block;
    .inner {opacity:1;}
  }
  .overlay{
    z-index:12;
    position:fixed;
    width:100vw;
    height:100vh;
    background: rgba(0,0,0,.4);
    backdrop-filter: blur(5px);
  }

  .inner {
    /*opacity:0;*/
    transition: opacity .25s;
    position:absolute;
    /*width:80vw;*/
    /*margin: 0 10vw;*/
    /*max-width:1200px;*/
    /*top:50%;*/
    z-index:13;
    /*transform: translateY(-50%);*/
    border: 10px solid #AFB5BA;
    background: #161616;  
    box-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px  #D8E8F9; 
    overflow-y:auto;
    padding:4%;

    color: #F0F7FF;
    font-family:'dm80_regular';
    font-size:22px;
    text-align:left;
    letter-spacing:.15rem;
    text-shadow: 0px 0px 30px rgba(147,197,249, .8), 0px 0px 5px #D8E8F9;
    text-transform:uppercase;

    &.full {
      width: 80vw;
      max-width: 1200px;
      margin: 0 10vw;
      top: 50%;
      transform: translateY(-50%);
     }

    .close {
      position:absolute;
      top:0;
      right:0;
      padding:0 0 3px 6px;
      color: #161616;
      background: #AFB5BA; 
      font-weight:bold;
      text-decoration:none;
      display:block;
    }
    h2 {
      margin-top:0;
      font-size: 33px;
    }
    ul {
      padding-left:2%;
      list-style: n;
      li {
        margin:20px 0;
        opacity: .8;
        list-style-type: disc;
      }
    }
  }
  .confirm {
    text-align: right;
    :first-child {
      margin-right: 1rem;
    }
  }
}

.finale-notice .inner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
